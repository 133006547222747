<template>
	<modal class="NoxModalTreeNode" name="NoxModalTreeNode" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 1" v-html="$store.getters.getLanguageText('5.7', 0)"></span>
						<span v-else-if="noxType == 2" v-html="$store.getters.getLanguageText('5.7', 1)"></span>
						<span v-else-if="noxType == 3" v-html="$store.getters.getLanguageText('5.7', 2)"></span>
						<span v-else v-html="$store.getters.getLanguageText('5.7', 3)"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div class="nox_modal_info_tree_node">
							<div v-if="noxType == 1"><p><b v-html="$store.getters.getLanguageText('5.7', 4)"></b>:<br><a :href="noxInvitationLink" target="_blank">{{ noxInvitationLink }}</a></p><p v-for="(value, index) in noxTreeNodeLandings" :key="index"><b>{{ value.name }}</b>:<br><a :href="$parent.$parent.$parent.getLandingLink(noxInvitation, value.codes)" target="_blank">{{ $parent.$parent.$parent.getLandingLink(noxInvitation, value.codes) }}</a></p><p v-html="$store.getters.getLanguageText('5.7', 5)"></p></div>
							<div v-else-if="noxType == 2" v-html="$store.getters.getLanguageText('5.7', 6)"></div>
							<div v-else-if="noxType == 3" v-html="$store.getters.getLanguageText('5.7', 7)"></div>
							<div v-else-if="noxType == 4"><p><b v-html="$store.getters.getLanguageText('5.7', 8)"></b>:<br><a :href="noxInvitationLink" target="_blank">{{ noxInvitationLink }}</a></p></div>
							<div v-else-if="noxType == 5"><p><b v-html="$store.getters.getLanguageText('5.7', 9)"></b>:<br><a :href="noxInvitationLink" target="_blank">{{ noxInvitationLink }}</a></p></div>
							<div v-else-if="noxType == 6" v-html="$store.getters.getLanguageText('5.7', 10)"></div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common blue" @click="$parent.$parent.$parent.clipboard(noxInvitationLink)" v-if="[1,4,5].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 53)"></button>
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxType: 0,
			noxInvitation: '',
			noxInvitationLink: '',
			noxTreeNodeLandings: [],
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxInvitation = event.params.invitation;
				this.noxInvitationLink = this.$store.state.noxBaseURL + '/?r=' + this.noxInvitation;
				this.noxTreeNodeLandings = [];
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 1) {
					this.axios('getTreeNodeData');
				}
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }
				else        { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'getTreeNodeData') {
						_this.noxIsOpenLoading = true;
						config.url = '/v2/account/tree/node';
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getTreeNodeData') {
									if (data.data.tree_landings !== undefined) {
										_this.noxTreeNodeLandings = data.data.tree_landings;
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 401) { _this.$parent.$parent.exit(); }
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalTreeNode');
			}
		}
	}
</script>
